<template>
  <div class="list">
    <van-tabs v-model="activeName" @click="onClick" type="card">
      <van-tab title="处理中" name="1">
        <van-list
          v-model="state.loading"
          :finished="state.finished"
          finished-text="没有更多了"
        >
          <div
            class="list-box"
            @click="toPage(item.returnId)"
            v-for="(item, index) in state.list"
            :key="index"
          >
            <div class="returnId-box">
              <div class="returnId color_title">
                <div class="tiao_icon"></div>
                <div class="ml1">工单号：</div>
                <div>{{ item.returnId }}</div>
                <!-- {{item.returnId}} -->
              </div>
              <div class="status">
                {{
                  item.status == 0
                    ? "待审核"
                    : item.status == -1
                    ? "拒绝"
                    : item.status == 1
                    ? "卖家待收货"
                    : item.status == 2
                    ? "买家待收货"
                    : item.status == 3
                    ? "已完成"
                    : item.status == 4
                    ? "已取消"
                    : item.status == 5
                    ? "检测中"
                    : "未知状态"
                }}
              </div>
            </div>
            <div class="xian"></div>
            <div class="returnId-box">
              <div class="returnId">
                <div>产品型号：</div>
                <div>{{ item.productTypeName }}</div>
              </div>
            </div>
            <div class="returnId-box">
              <div class="returnId">
                <div style="width: 8rem">故障描述：</div>
                <div style="word-break: break-all; width: 100%">
                  {{ item.faultDescribe }}
                </div>
              </div>
            </div>
            <div class="returnId-box">
              <div class="returnId" style="align-items: center">
                <div>申请时间：</div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
            <div class="xian"></div>
            <div class="btn_a">
              <van-button
                type="default"
                class="btn_w"
                style="background-color: #ff7775; color: #fff"
                @click.stop="cancelProductReturn(item.returnId)"
                v-if="item.status == 1 || item.status == 5 || item.status == 0"
                >取消售后</van-button
              >
              <van-button
                class="btn_w"
                v-if="item.status == 1 && !item.buyerExpressNumber"
                type="default"
                @click.stop="lookShow(item.returnId)"
                >填写物流单号</van-button
              >
              <van-button
                class="btn_w"
                type="default"
                v-if="item.status == 5 && item.serverPrice"
                @click.stop="payServerFee(item.returnId)"
                >支付费用</van-button
              >
              <van-button
                type="default"
                class="btn_w"
                v-if="item.status == 2"
                @click.stop="confirmProductReturn(item.returnId)"
                >确认收货</van-button
              >

              <div
                class="show_btn"
                @click.stop="openMask = true"
                v-if="
                  (item.status == 1 || item.status == 0) &&
                  !item.buyerExpressNumber
                "
              >
                查看寄件地址
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="已完成" name="2">
        <van-list
          v-model="state.loading"
          :finished="state.finished"
          finished-text="没有更多了"
        >
          <div
            class="list-box"
            @click="toPage(item.returnId)"
            v-for="(item, index) in state.list"
            :key="index"
          >
            <div class="returnId-box">
              <div class="returnId color_title">
                <div class="tiao_icon"></div>
                <div class="ml1">工单号：</div>
                <div>{{ item.returnId }}</div>
                <!-- {{item.returnId}} -->
              </div>
              <div class="status">
                {{
                  item.status == 0
                    ? "待审核"
                    : item.status == -1
                    ? "拒绝"
                    : item.status == 1
                    ? "卖家待收货"
                    : item.status == 2
                    ? "买家待收货"
                    : item.status == 3
                    ? "已完成"
                    : item.status == 4
                    ? "已取消"
                    : item.status == 5
                    ? "检测中"
                    : "未知状态"
                }}
              </div>
            </div>
            <div class="xian"></div>
            <div class="returnId-box">
              <div class="returnId">
                <div>产品型号：</div>
                <div>{{ item.productTypeName }}</div>
              </div>
            </div>
            <div class="returnId-box">
              <div style="width: 8rem">故障描述：</div>
              <div style="word-break: break-all; width: 100%">
                {{ item.faultDescribe }}
              </div>
            </div>
            <div class="returnId-box">
              <div class="returnId">
                <div>申请时间：</div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
            <div class="xian"></div>
            <div class="btn_a"></div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-overlay :show="show" @click.stop="show = false">
      <!-- <div class="wrapper"  @click.stop>
        <div class="block">
          <div>邮寄地址：</div>
          <van-field
            v-model="orderNo"
            name="productSerialNumber"
            label="请输入物流单号"
            placeholder="请输入物流单号"
            :rules="[{ required: true, message: '请填写物流单号' }]"
          />
          <van-button @click.stop="lookMallingAddress" type="primary"
            >确定</van-button
          >
        </div>
      </div> -->

      <div class="box_adress" @click.stop>
        <div class="adress_title">
          <div class="adress_title_name">填写物流单号</div>
          <div class="adress_title_btn" @click.stop="lookMallingAddress">
            确定
          </div>
        </div>
        <van-field
          v-model="orderNo"
          name="productSerialNumber"
          class="adress_input"
          placeholder="请输入物流单号"
          :rules="[{ required: true, message: '请填写物流单号' }]"
        />
        <div class="tips">为了不影响售后进度，物流单号请填写准确哦</div>
      </div>
      <img
        class="close_img"
        @click.stop="show = false"
        src="../assets/guanbi@2x.png"
      />
    </van-overlay>
    <van-overlay :show="openMask" @click.stop="openMask = false">
      <div class="box_adress" @click.stop>
        <div class="adress_title">
          <div class="adress_title_name">寄件地址</div>
          <div class="adress_title_btn" @click.stop="openMask = false">
            确定
          </div>
        </div>
        <div class="tip">
          <div>详细地址:</div>
          <div>
            {{ address.province }}{{ address.city }}{{ address.region
            }}{{ address.detailAddress }}
          </div>
        </div>
        <div class="tiao_tip"></div>
        <div class="tip">
          <div>联系人:</div>
          <div>{{ address.name }}</div>
        </div>
        <div class="tiao_tip"></div>
        <div class="tip">
          <div>联系电话:</div>
          <div>{{ address.phone }}</div>
        </div>
      </div>
      <img
        class="close_img"
        @click.stop="openMask = false"
        src="../assets/guanbi@2x.png"
      />
    </van-overlay>
  </div>
</template>

<script>
import { Tabs, List, Tab, Card, Overlay, Field, Dialog, Toast } from "vant";
import wx from "weixin-js-sdk";
import {
  getProductReturnList,
  lookMallingAddress,
  confirmProductReturn,
  cancelProductReturn,
  wxPublicAuth,
  getAdress,
  payServerFee,
  wxPublicImpower,
  companyAddress,
} from "../api/home";
export default {
  name: "List",
  components: {
    "van-list": List,
    "van-tabs": Tabs,
    "van-tab": Tab,
    "van-card": Card,
    "van-overlay": Overlay,
    "van-field": Field,
  },
  data() {
    return {
      activeName: "1",
      returnId: "",
      show: false,
      orderNo: "",
      openMask: false,
      address: {},
      state: {
        loading: false,
        finished: false,
        list: [],
        operations: 1,
        pageNum: 1,
        pageSize: 1000,
      },
    };
  },
  created() {
    this.wxPublicImpower();
    this.companyAddress();
  },
  methods: {
    wxPublicImpower() {
      var href = window.location.href;
      wxPublicImpower({
        href,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp: Number(res.data.timestamp), // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
            });
            console.log("wx", wx);
            this.onLoad();
            this.getAdress();
            var url = window.location.href;
            console.log("url", url);
            var theRequest = new Object();
            if (url.indexOf("?") != -1) {
              var strs = url.split("?");
              var strs1 = strs[1].split("&");
              console.log("strs", strs);
              for (var i = 0; i < strs1.length; i++) {
                theRequest[strs1[i].split("=")[0]] = unescape(
                  strs1[i].split("=")[1]
                );
              }
            }
            console.log("theRequest：：：：：：：：：：：：", theRequest);
            if (theRequest.token) {
              localStorage.setItem("token", theRequest.token);
              localStorage.setItem("openId", theRequest.openId);
            } else {
              if (
                localStorage.getItem("token") == null ||
                localStorage.getItem("token") == ""
              ) {
                this.wxPublicAuth();
              }
            }
            console.log(theRequest);
            wx.error(function (res) {
              console.log("接口签名认证错误", res);
            });
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    wxPublicAuth() {
      wxPublicAuth({
        page: "list",
      })
        .then((res) => {
          console.log(" window.location.href", res.data);
          window.location.href = res.data;
          
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getAdress() {
      getAdress({})
        .then((res) => {
          console.log(res);
        })
        .catch((res) => {});
    },
    companyAddress() {
      companyAddress({})
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.address = res.data;
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch((res) => {});
    },
    payServerFee(productReturnId) {
      payServerFee({
        productReturnId,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            wx.chooseWXPay({
              timestamp: res.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: res.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: res.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: res.data.paySign, // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                this.state.data = {};
                this.state.list = [];
                this.onLoad();
              },
              fail() {
                this.state.data = {};
                this.state.list = [];
                this.onLoad();
              },
            });
          } else {
            Toast.fail(res.msg);
          }
        })
        .catch((res) => {});
    },
    // 取消售后
    cancelProductReturn(returnId) {
      Dialog.alert({
        message: "是否取消售后",
        theme: "round-button",
        showCancelButton: true,
        showConfirmButton: true,
      })
        .then(() => {
          cancelProductReturn({
            returnId,
          }).then((res) => {
            console.log(res);
            this.returnId = "";
            if (res.code == 200) {
              this.onLoad();
            } else {
              Toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
      return false;
    },
    //前往订单详情
    toPage(val) {
      console.log(val);
      this.$router.push({
        path: "/detail",
        query: {
          id: val,
        },
      });
    },
    //确认收货
    confirmProductReturn(returnId) {
      Dialog.alert({
        message: "是否确认收货",
        theme: "round-button",
        showCancelButton: true,
        showConfirmButton: true,
      })
        .then(() => {
          confirmProductReturn({
            returnId,
          }).then((res) => {
            console.log(res);
            this.returnId = "";
            if (res.code == 200) {
              this.activeName = 2;
              this.state.operations = 2;
              this.onLoad();
            } else {
              Toast.fail(res.msg);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
      return false;
    },
    // 输入快递单号
    lookMallingAddress() {
      if (!this.orderNo) {
        Toast.fail("请输入快递单号");
        return false;
      }
      lookMallingAddress({
        orderNo: this.orderNo,
        returnId: this.returnId,
      }).then((res) => {
        console.log(res);

        if (res.code == 200) {
          this.returnId = "";
          this.orderNo = "";
          Toast.success("物流单号添加成功");
          this.show = false;
          this.onLoad();
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    lookShow(id) {
      this.show = true;
      this.returnId = id;
      console.log(id);
    },
    onLoad() {
      this.state.list = []
      this.state.data = {}
      getProductReturnList({
        operations: this.state.operations,
        pageNum: this.state.pageNum,
        pageSize: this.state.pageSize,
        openId: localStorage.getItem("openId"),
      }).then((res) => {
        console.log(res);
        this.state.finished = true;
        if (res.code == 200) {
          this.state.data = res.data;
          this.state.list = res.data.list;
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    onClick(e) {
      console.log(e);
      if (e == 1) {
        this.state = {
          loading: false,
          finished: false,
          list: [],
          operations: 1,
          pageNum: 1,
          pageSize: 10,
        };
      } else {
        this.state = {
          loading: false,
          finished: false,
          list: [],
          operations: 2,
          pageNum: 1,
          pageSize: 10,
        };
      }
      this.onLoad();
    },
  },
};
</script>
<style>
.tiao_tip {
  width: 100%;
  height: 1px;
  margin-top: 1.22rem;
  background-color: #f5f5f5;
}
.show_btn {
  font-size: 0.88rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3d32ff;
  line-height: 100%;
  margin-right: 0.5rem;
}
.tip {
  width: 100%;
  height: 1.31rem;
  font-size: 0.94rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 1.31rem;
  color: #333;
  margin-top: 1.22rem;
  display: flex;
  flex-direction: row;
}
.tip div:nth-child(1) {
  color: #777777;
  width: 4.75rem;
  margin-right: 1.22rem;
}
.close_img {
  width: 2.75rem;
  height: 2.75rem;
  /* margin:0 auto; */
  margin-top: 1.59rem;
  margin-left: 10.4rem;
}
.tips {
  width: 100%;
  text-align: center;
  height: 1.03rem;
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5b5b;
  line-height: 1.03rem;
  margin-top: 0.78rem;
}
.adress_input {
  border: 1px solid #333333;
  border-radius: 0.31rem;
  margin-top: 1.31rem;
}
.adress_title_btn {
  color: #ff5b5b;
}
.adress_title_name {
  color: #333;
}
.adress_title {
  display: flex;
  justify-content: space-between;
  font-size: 1.13rem;
  line-height: 1.56rem;
  height: 1.56rem;
}
.box_adress {
  width: 18rem;
  padding: 1.47rem 0.81rem 0.81rem 0.81rem;
  margin-top: 15rem;
  margin-left: 2rem;
  background-color: #fff;
  border-radius: 0.31rem;
}
.list {
  background-color: #f5f5f5;
}
.tiao_icon {
  width: 0.38rem;
  height: 0.96rem;
  background: #ff7775;
  border-radius: 0.31rem;
}
.van-tabs__nav--card .van-tab.van-tab--active {
  background-color: #ff7775;
}
.van-tabs__nav--card .van-tab {
  color: #ff7775;
  border-right: 1px solid #ff7775;
}
.xian {
  height: 0.06rem;
  width: 100%;
  background-color: #f5f5f5;
  margin-top: 0.81rem;
}
.van-tabs__nav--card {
  box-sizing: border-box;
  height: 30px;
  margin: 0px;
  border: 1px solid #ff7775;
  border-radius: 2px;
}
.ml1 {
  margin-left: 1.25rem;
}
.btn_a {
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.btn_w {
  margin-right: 0.5rem;
}
.van-button--normal {
  padding: 0 8px;
}
.list-box {
  padding: 1rem 2rem;
  margin: 1rem 0;
  background: #fff;
  box-shadow: 0px 1px 1px #f6f6f6;
}
.status {
  color: #ff5b5b !important;
}
.returnId-box {
  display: flex;
  font-size: 0.96rem;
  margin-top: 1rem;
  justify-content: space-between;
  color: #333333;
}
.returnId {
  display: flex;
  font-size: 0.9rem;
}
.color_title {
  color: #aaa;
}
.returnId :nth-child(1) {
  color: #333333;
}

.status {
  color: #f40;
  line-height: 0.96rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.block {
  width: 30rem;
  height: 30rem;
  background-color: #fff;
}
</style>
